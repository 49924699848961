import { lazy } from "react";
import { Row } from "antd";
import { withTranslation } from "react-i18next";

import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));

const Header = ({ t }) => {

  return (
    <S.Header>
      <S.Container>
        <Row type="flex" gutter={20}>
          <S.LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" height={65} width={65} />
          </S.LogoContainer>
          <S.Title>
            AlertaT
          </S.Title>
        </Row>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
