const routes = [
  {
    path: ["/action"],
    exact: true,
    component: "Action",
  },
  {
    path: ["*"],
    exact: true,
    component: "NotFound",
  }
];

export default routes;
